import {
  BASIC_SKU,
  STATUS,
  SUBSCRIPTIONS,
} from "@/helpers/variables/backendConstants";

import {
  getPriceByPeriod,
  getTotalPlanPrice,
  getTotalPlanPriceWithoutDiscount,
} from "@/helpers/services/billing";
import { hasDecimal } from "@/helpers/services/utils";

import { defaultDiscounts } from "@/helpers/variables/translateString";

const state = () => ({
  selfCheckoutPlan: null,
  maxLicenses: 0,
  selfCheckoutSubscriptionType: "FLEX",
  selfCheckoutPaymentMethod: "ticket",
  interestedInService: false,
});

const getters = {
  selfCheckoutPlan: (state) => state.selfCheckoutPlan,

  isIntrestedInService: (state) => state.interestedInService,

  selfCheckoutPlanName: (state) =>
    state.selfCheckoutPlan ? state.selfCheckoutPlan.name : "",

  selfCheckoutPlanSlug: (state, getters) =>
    getters.selfCheckoutPlanName.toLowerCase().replace(" ", "_"),

  selfCheckoutPlanKey: (state) =>
    state.selfCheckoutPlan ? state.selfCheckoutPlan.key : "",

  selfCheckoutPlanProducts: (state) =>
    state.selfCheckoutPlan && state.selfCheckoutPlan.products
      ? state.selfCheckoutPlan.products.map((product) => product.name)
      : [],

  selfCheckoutPaymentMethod: (state) => state.selfCheckoutPaymentMethod,

  isCreditCardPayment: (state) =>
    state.selfCheckoutPaymentMethod === "credit_card",

  companyHasThisPlanActiveOrTrial: (state, getters, rootState, rootGetters) => {
    const statusSet = [STATUS.ACTIVE, STATUS.TRIAL];

    return rootGetters.contractedPlans.filter(
      (plan) =>
        statusSet.includes(plan.valid_status) &&
        plan.plan_key === getters.selfCheckoutPlanKey
    );
  },

  companyImpactedPlansInTrial: (state, getters, rootState, rootGetters) => {
    const statusSet = [STATUS.ACTIVE, STATUS.TRIAL];
    const productNames = getters.selfCheckoutPlanProducts; // Produtos do plano atual

    let clientPlans = rootGetters.contractedPlans.filter(
      (plan) =>
        statusSet.includes(plan.status) &&
        plan.plan_key !== getters.selfCheckoutPlanKey
    );

    if (getters.selfCheckoutPlanKey && clientPlans) {
      return clientPlans.filter((companyPlan) => {
        let clientProducts = companyPlan.plan.products;

        return clientProducts.find((product) => {
          if (productNames.includes(product.name)) {
            return companyPlan;
          }
        });
      });
    }
    return [];
  },

  companyImpactedPlans: (state, getters, rootState, rootGetters) => {
    const statusSet = [STATUS.ACTIVE];
    const productNames = getters.selfCheckoutPlanProducts; // Produtos do plano atual

    let clientPlans = rootGetters.contractedPlans.filter(
      (plan) =>
        statusSet.includes(plan.status) &&
        plan.plan_key !== getters.selfCheckoutPlanKey
    );

    if (getters.selfCheckoutPlanKey && clientPlans) {
      return clientPlans.filter((companyPlan) => {
        let clientProducts = companyPlan.plan.products;

        return clientProducts.find((product) => {
          if (productNames.includes(product.name)) {
            return companyPlan;
          }
        });
      });
    }
    return [];
  },

  selfCheckoutMaxLicenses: (state) => state.maxLicenses,

  selfCheckoutSubscriptionType: (state) => state.selfCheckoutSubscriptionType,

  userHasSelectedPlan: (state, getters, rootState, rootGetters) => {
    const key = getters.selfCheckoutPlanKey;
    const subscriptionType = getters.selfCheckoutSubscriptionType;
    if (rootGetters.contractedPlans) {
      return rootGetters.contractedPlans.find(
        (plan) =>
          plan.key === key &&
          plan.subscription_type === subscriptionType &&
          [STATUS.ACTIVE].includes(plan.valid_status)
      );
    }
    return false;
  },

  companyDiscountByPartner: (state, getters, rootState, rootGetters) => {
    if (rootGetters.company.reseller_company) {
      return parseFloat(rootGetters.company.reseller_company.discount);
    }
    return 0;
  },

  selfCheckoutDiscount: (state, getters) => {
    const discountBySubscription = getters.selfCheckoutSubscriptionType
      ? defaultDiscounts[getters.selfCheckoutSubscriptionType]
      : 0;

    if (getters.userHasSelectedPlan) {
      return getters.userHasSelectedPlan.discount;
    } else if (getters.companyDiscountByPartner > 0) {
      return (
        1 -
        (1 - discountBySubscription) * (1 - getters.companyDiscountByPartner)
      );
    }
    return discountBySubscription;
  },

  selfCheckoutDiscountBySubscripton: (state, getters) => {
    return {
      ANNUAL: getters.annualWithPartnerDiscount / 100,
      FLEX: getters.flexWithPartnerDiscount / 100,
    };
  },

  annualDefaultDiscountText: () => (defaultDiscounts.ANNUAL * 100).toFixed(2),

  annualWithPartnerDiscount: (state, getters) => {
    const discount =
      1 -
      (1 - defaultDiscounts.ANNUAL) * (1 - getters.companyDiscountByPartner);

    return (discount * 100).toFixed(2);
  },

  flexWithPartnerDiscount: (state, getters) => {
    const discount =
      1 - (1 - defaultDiscounts.FLEX) * (1 - getters.companyDiscountByPartner);

    return (discount * 100).toFixed(2);
  },

  selfCheckoutDiscountText: (state, getters) => {
    if (getters.selfCheckoutDiscount > 0) {
      let discount = parseFloat(getters.selfCheckoutDiscount) * 100 || 0;

      if (hasDecimal(discount)) {
        return `${discount.toFixed(2)}%`;
      }
      return `${parseInt(discount)}%`;
    }
    return "";
  },

  billiableLicenses: (state, getters, rootState, rootGetters) => {
    if (getters.selfCheckoutSubscriptionType === SUBSCRIPTIONS.ANNUAL) {
      return getters.selfCheckoutMaxLicenses;
    }
    return rootGetters.usersNumber;
  },

  selfChekoutTotalPrice: (state, getters) => {
    if (state.selfCheckoutPlan) {
      const { price } = getters.selfCheckoutPlan;
      return getTotalPlanPrice(
        getters.billiableLicenses,
        getters.selfCheckoutSubscriptionType,
        getters.selfCheckoutDiscount,
        price
      );
    }
    return 0;
  },

  selfCheckoutTotalPriceWithoutDiscount: (state, getters) => {
    if (state.selfCheckoutPlan) {
      const { price } = getters.selfCheckoutPlan;
      return getTotalPlanPriceWithoutDiscount(
        getters.billiableLicenses,
        getters.selfCheckoutSubscriptionType,
        price
      );
    }
    return 0;
  },

  selfCheckoutDiscountInMoney: (state, getters) =>
    getters.selfCheckoutTotalPriceWithoutDiscount -
    getters.selfChekoutTotalPrice,

  selfCheckoutPriceByPeriod: (state, getters) => {
    if (state.selfCheckoutPlan) {
      const { price } = getters.selfCheckoutPlan;
      return getPriceByPeriod(
        getters.selfCheckoutSubscriptionType,
        price,
        getters.selfCheckoutDiscount
      );
    }
    return 0;
  },

  temporaryCompanyPlan: (state, getters, rootState, rootGetters) => {
    if (state.selfCheckoutPlan) {
      let companyPlan = {
        plan: state.selfCheckoutPlan,
        licenses: rootGetters.usersNumber,
        price: state.selfCheckoutPlan.price,
        subscription_type: getters.selfCheckoutSubscriptionType,
        discount:
          getters.selfCheckoutDiscountBySubscripton[
            getters.selfCheckoutSubscriptionType
          ],
      };

      if (getters.selfCheckoutSubscriptionType === SUBSCRIPTIONS.ANNUAL) {
        companyPlan.max_licenses = getters.selfCheckoutMaxLicenses;
      }

      return companyPlan;
    }
    return {};
  },
  isBasicFreeSelected: (state) => {
    return state.selfCheckoutPlan
      ? state.selfCheckoutPlan.sku === BASIC_SKU
      : false;
  },
};

// actions
const actions = {
  selfCheckoutSendSuperLogicaEmailPayment() {
    let url =
      process.env.VUE_APP_API_BASE_URL +
      "/integrations/superlogica/send-email-payment";
    const auth = {
      headers: { Authorization: this._vm.token },
    };
    return this._vm.$axios.post(url, {}, auth);
  },
};

// mutations
const mutations = {
  setSelfCheckoutPlanInCart(state, plan) {
    state.selfCheckoutPlan = plan;
  },

  setSelfCheckoutMaxLicenses(state, licenses) {
    state.maxLicenses = licenses;
  },

  setSelfCheckoutSubscriptionType(state, subscription_type) {
    state.selfCheckoutSubscriptionType = subscription_type;
  },

  setSelfCheckoutPaymentMethod(state, payment_method) {
    state.selfCheckoutPaymentMethod = payment_method;
  },

  setIntrestInService(state, value) {
    state.interestedInService = value;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
