export const paging = {
  All: [25, 50, 100, 200],
  perPage: 50,
};
export const backendPaging = {
  All: [25, 50, 100, 200],
  perPage: 50,
};

export const tableHeight = "calc(90vh - 220px)";

export const newUsersTableHeigth = "calc(80vh - 220px)";

export const headersTitles = {
  applied_signature_key: "Assinatura de e-mail",
  applied_vacation_key: "Mensagem de férias",
  departament: "Departamento",
  domain: "Domínio",
  email: "E-mail",
  is_google_admin: "Função",
  is_primary_email: "E-mail primário",
  name: "Nome",
  users: "Usuário",
  position: "Posição",
  "reseller_company.name": "Parceiro atual",
  "current_plan.*.subscription_type": "Plano de pagamento",
  "current_plan.*.status": "Status do plano",
  "current_plan.*.plan.name": "Plano",
  "current_plan.*.end_trial": "Encerramento de trial",
  id_google: "ID Google",
  main_domain: "Domínio",
  users_number: "Número de usuários",
  is_reseller: "Revendedor",
  created_at: "Data de criação",
  responsible_name: "Nome do responsável",
  responsible_email: "E-mail do responsável",
  financial_email: "E-mail do financeiro",
  phone: "Telefone",
  cnpj: "CNPJ",
  address_postal_code: "CEP",
  address_info: "Endereço",
  plan_name: "Nome do plano",
  plan_sku: "SKU",
  plan_status: "Status do plano",
  plan_licenses: "Licenças ativas",
  plan_max_licenses: "Licenças contratadas",
  plan_end_trial: "Data de final de trial",
  plan_end_date: "Data de encerramento do plano",
  plan_subscription_type: "Plano de pagamento",
  plan_price_per_period: "Valor da licença por periodo",
  plan_price_period_per_month: "Valor da licença por mês",
  plan_price: "Valor do plano",
  company_plan_discount: "Desconto do company_plan",
  plan_next_billing: "Próxima cobrança",
  contract: "Contrato",
  reseller_name: "Nome do Revendedor",
  reseller_domain: "Domínio do Revendedor",
  reseller_type: "Tipo do Revendedor",
  reseller_commission: "Comissão do Revendedor (%)",
  reseller_discount: "Desconto do Revendedor (%)",
  tags: "Tags",
  ou: "Unidade organizacional",
  suspended: "Status do cliente",
  cancellation_date: "Status do cliente",
};
