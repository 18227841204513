export const TRIAL = "TRIAL";
export const ACTIVE = "ACTIVE";
export const DISABLED = "DISABLED";
export const TRIAL_EXPIRED = "TRIAL_EXPIRED";
export const MAX_TAGS_PER_USER = 30;
export const FREE = "FREE";
export const SUSPENDED = "SUSPENDED";
export const CANCELLED = "CANCELLED";

export const partnerType = {
  INTERMEDIARY: "INTERMEDIARY",
  DIRECT: "DIRECT",
};

export const SUBSCRIPTIONS = {
  FLEX: "FLEX",
  QUARTELY: "QUARTELY",
  SEMESTER: "SEMESTER",
  ANNUAL: "ANNUAL",
  BIENNIUM: "BIENNIUM",
  EXTRA: "EXTRA",
  ONCE: "ONCE",
  TRIAL: "TRIAL",
  FREE: "FREE",
};

export const STATUS = {
  TRIAL: TRIAL,
  DISABLED: DISABLED,
  ACTIVE: ACTIVE,
  TRIAL_EXPIRED: TRIAL_EXPIRED,
  FREE: FREE,
  SUSPENDED: SUSPENDED,
  CANCELLED: CANCELLED,
};

export const responseStatus = {
  PRODUCT_DOES_NOT_EXIST_IN_PLANS: "PRODUCT_DOES_NOT_EXIST_IN_PLANS",
  PRODUCT_IN_DISABLED_PLAN: "PRODUCT_IN_DISABLED_PLAN",
  TRIAL_ENDED: "PLAN_TRIAL_ENDED",
  USER_IS_NOT_ADMIN: "USER_IS_NOT_ADMIN",
  UNAUTHORIZED: "USER_IS_NOT_ALLOWED_TO_ACCESS_THIS_RESOURCE",
  USER_IS_NOT_ALLOWED_TO_ACCESS_THIS_RESOURCE:
    "USER_IS_NOT_ALLOWED_TO_ACCESS_THIS_RESOURCE",
  RESOURCE_NOT_FOUND: "RESOURCE_NOT_FOUND",
  ACCESS_ALLOWED: "ACCESS_ALLOWED",
};

export const googleAdminValues = [
  { search: "true", text: "Administrador" },
  { search: "false", text: "Membro" },
];

export const userStatus = [
  { search: "false", text: "Ativo" },
  { search: "true", text: "Suspenso" },
];

export const SUITE_DEV_PROJECT_NAME = "dev-conectasuite";
export const SUITE_PROD_PROJECT_NAME = "conectasuite";

export const companyPlan = {
  canceled: null,
  company_key: null,
  discount: null,
  end_date: null,
  end_transaction: null,
  end_trial: null,
  init_billing: null,
  init_date: null,
  init_transaction: null,
  installments: null,
  key: null,
  max_licenses: null,
  next_billing: null,
  notes: null,
  partner_commission: null,
  plan: null,
  plan_key: null,
  price: null,
  status: null,
  subscription_type: null,
};

export const ENTERPRISE_SKU = "ENT-052024";
export const SECURITY_SKU = "SEC-052024";
export const STANDARD_SKU = "STA-052024";
export const BASIC_SKU = "BAS-072023";

export const getAssociatedServiceSKUByPlan = (plan_sku) => {
  const environment = process.env.NODE_ENV;

  const servicesBySKU = {
    development: {
      [STANDARD_SKU]: "IMP-AGI-STA-042023",
      [SECURITY_SKU]: "IMP-AGI-SEC-042023",
      [ENTERPRISE_SKU]: "IMP-AGI-SEC-042023",
    },
    production: {
      [STANDARD_SKU]: "IMP-AGI-STA-062024",
      [SECURITY_SKU]: "IMP-AGI-SEC-062024",
      [ENTERPRISE_SKU]: "IMP-AGI-SEC-062024",
    },
  };

  return servicesBySKU[environment][plan_sku];
};
