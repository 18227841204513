import { SECURITY_SKU } from "@/helpers/variables/backendConstants";

export const modules = {
  suite: "conecta_suite",
  track: "conecta_track",
  sign: "conecta_sign",
  control: "conecta_control",
  transfer: "conecta_transfer",
  support: "conecta_support",
  reseller: "reseller",
  manageAdmins: "manage_admins",
  companyProfile: "company_profile",
  billing: "billing",
  reports: "reports",
  manage_admins: "manage_admins",
  users: "users",
  inventory: "inventory",
  contact_us: "contact_us",
  workspace_diagnosis: "workspace_diagnosis",
};

export const modulesDetails = {
  conecta_sign: {
    title: "common.conectaSign",
    key: "conecta_sign",
    description: "dashboard.conectaSign.description",
    short_description: "dashboard.conectaSign.shortDescription",
    image: require("@/assets/appslogos/icons/icon-sign.png"),
  },
  conecta_track: {
    title: "common.conectaTrack",
    key: "conecta_track",
    description: "dashboard.conectaTrack.description",
    short_description: "dashboard.conectaTrack.shortDescription",
    image: require("@/assets/appslogos/icons/icon-track.png"),
  },
  conecta_transfer: {
    title: "common.conectaTransfer",
    key: "conecta_transfer",
    description: "",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-transfer.png"),
  },
  conecta_control: {
    title: "sidebar.conectaControl",
    key: "conecta_control",
    description: "dashboard.conectaControl.description",
    short_description: "dashboard.conectaControl.shortDescription",
    image: require("@/assets/appslogos/icons/icon-control.png"),
  },
  conecta_suite: {
    title: "common.conectaSuite",
    key: "conecta_suite",
    description: "dashboard.conectaSuite.description",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  workspace_diagnosis: {
    title: "sidebar.techDiagnosis",
    key: "workspace_diagnosis",
    description: "dashboard.workspaceDiagnosis.description",
    short_description: "dashboard.workspaceDiagnosis.description",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  reseller: {
    title: "common.conectaPartners",
    key: "reseller",
    description: "dashboard.reseller.description",
    short_description: "dashboard.reseller.shortDescription",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  manage_admins: {
    title: "common.usersPermissions",
    key: "admin",
    description: "dashboard.manageAdmins.description",
    short_description: "dashboard.manageAdmins.description",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  company_profile: {
    title: "companyProfile.companyProfile",
    key: "profile",
    description: "dashboard.companyProfile.description",
    short_description: "dashboard.companyProfile.shortDescription",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  billing: {
    title: "sidebar.billing",
    key: "billing",
    description: "dashboard.billing.description",
    short_description: "dashboard.billing.shortDescription",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  users: {
    title: "techDiagnosis.tableHeaders.users",
    key: "users",
    description: "dashboard.users.description",
    short_description: "dashboard.users.description",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  inventory: {
    title: "sidebar.inventory",
    key: "inventory",
    description: "dashboard.inventory.description",
    short_description: "dashboard.inventory.description",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  reports: {
    title: "sidebar.reports",
    key: "reports",
    description: "dashboard.reports.description",
    short_description: "dashboard.reports.description",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  contact_us: {
    title: "common.contactUs",
    key: "contact_us",
    description: "dashboard.contactUs.description",
    short_description: "dashboard.contactUs.description",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
};

export const productPlanDetails = {
  conecta_suite: {
    title: "Basic",
    key: "conecta_suite",
    link: "https://conectasuite.com/plano-basic/",
  },
  conecta_sign: {
    title: "Standard",
    key: "conecta_sign",
    link: "https://conectasuite.com/plano-standard/",
  },
  conecta_control: {
    title: "Security",
    key: "conecta_control",
    link: "https://conectasuite.com/plano-security/",
  },
};

export const cardsName = {
  users: "techDiagnosis.tableHeaders.users",
  inventory: "sidebar.inventory",
  company: "companyProfile.companyProfile",
  manage_admins: "common.usersPermissions",
  reseller: "common.conectaPartners",
  track: "common.conectaTrack",
  sign: "dashboard.manageEmailSignatures",
  access: "sidebar.conectaControl",
  control: "sidebar.conectaControl",
  reports: "sidebar.reports",
  billing: "sidebar.billing",
  contact_us: "common.contactUs",
  workspace_diagnosis: "sidebar.techDiagnosis",
};

export const FEATURED_SKU = SECURITY_SKU;
