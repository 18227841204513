const state = () => ({
  resellers: [],
  hasResellerPermission: undefined,
  clientToken: "",
  isResellerPage: false,
});

const getters = {
  resellers: (state) => state.resellers,
  resellersList: (state) =>
    state.resellers.map((item) => {
      return {
        name: item.name,
        domain: item.main_domain,
        key: item.cs_token,
        value: item.name,
      };
    }),
  conectaNuvemResellerToken: () => process.env.VUE_APP_RESELLER_TOKEN,
  hasResellerPermission: (state) => state.hasResellerPermission,
  clientToken: (state) => state.clientToken,
  isResellerPage: (state) => state.isResellerPage,
};

// actions
const actions = {
  getResellers({ commit }) {
    const url = process.env.VUE_APP_API_BASE_URL + "/resellers";

    return this._vm.$axios
      .get(url, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        commit("setResellers", data);
      })
      .catch((e) => {
        console.error("Vuex: getResellers()", e);
      });
  },
  updateClientReseller(context, info) {
    const url =
      process.env.VUE_APP_API_BASE_URL +
      `/clients/${info.client_key}/reseller-token`;

    return this._vm.$axios.put(url, info.payload, {
      headers: {
        Authorization: this._vm.token,
      },
    });
  },
  checkResellerPermission({ getters, commit }) {
    if (getters.hasResellerPermission == undefined) {
      return this._vm.$axios
        .get(process.env.VUE_APP_API_BASE_URL + "/reseller/permission", {
          headers: { Authorization: this._vm.token },
        })
        .then((response) => {
          commit("setResellerPermission", response);
        });
    } else {
      return getters.hasResellerPermission;
    }
  },
  checkHasResellerPermission({ dispatch }) {
    return dispatch("checkHasPagePermissions", "reseller");
  },
};

// mutations
const mutations = {
  setResellers(state, resellers) {
    state.resellers = resellers;
  },
  setResellerPermission(state, permission) {
    state.hasResellerPermission = permission;
  },
  setClientToken(state, token) {
    state.clientToken = token;
  },
  setIsResellerPage(state, value) {
    state.isResellerPage = value;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
