<template>
  <v-dialog
    v-model="scheduleMeetingSnackbar"
    max-width="1000"
    @keydown.esc="scheduleMeeting(false)"
    @click:outside="scheduleMeeting(false)"
  >
    <v-card>
      <v-toolbar elevation="0" dense>
        <v-spacer></v-spacer>
        <v-btn icon @click="scheduleMeeting(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="ma-0">
        <v-col v-if="$vuetify.breakpoint.mdAndUp">
          <v-img
            max-width="450px"
            :src="scheduleImage"
            alt="schedule meeting"
            class="mb-6"
          ></v-img>
          <a style="font-size: 10px" href="https://storyset.com/communication"
            >Communication illustrations by Storyset</a
          >
        </v-col>
        <v-col
          style="display: flex; justify-content: center; align-items: center"
        >
          <div v-if="!isSmallClient">
            <div
              v-if="!$vuetify.breakpoint.mdAndUp"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <v-img
                max-width="250px"
                :src="scheduleImage"
                alt="schedule meeting"
                class="mb-6"
              ></v-img>
            </div>
            <v-card-title
              class="title text-h4 accentSuite--text font-weight-bold text-wrap break-word"
            >
              {{ $t("common.hello") + " " + getFirstName }},
            </v-card-title>
            <v-card-subtitle class="text-h5 text--bold title font-weight-bold">
              {{ $t("common.letsTalk") }}
            </v-card-subtitle>
            <v-card-text class="py-4 body-1">
              {{ $t("scheduleMeeting.message") }}
            </v-card-text>
            <v-card-actions class="mt-5">
              <v-btn
                color="accentSuite"
                class="white--text text-none text-body-1"
                width="100%"
                large
                elevation="0"
                rounded
                @click="scheduleMeeting"
              >
                {{ $t("scheduleMeeting.talkToOurSpecialists") }}
                <v-icon v-text="'mdi-arrow-right'" class="next-icon-1" right />
              </v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn
                color="primarySuite"
                class="white--text text-none text-body-1"
                width="100%"
                large
                outlined
                elevation="0"
                rounded
                @click="redirectToSelfCheckout"
              >
                {{ $t("scheduleMeeting.knowOurPlans") }}
                <v-icon v-text="'mdi-arrow-right'" class="next-icon-2" right />
              </v-btn>
            </v-card-actions>
            <a
              v-if="!$vuetify.breakpoint.mdAndUp"
              style="font-size: 10px"
              href="https://storyset.com/communication"
              >Communication illustrations by Storyset</a
            >
          </div>
          <div v-else>
            <div
              v-if="!$vuetify.breakpoint.mdAndUp"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <v-img
                max-width="250px"
                :src="scheduleImage"
                alt="schedule meeting"
                class="mb-6"
              ></v-img>
            </div>
            <v-card-title
              class="title text-h4 accentSuite--text font-weight-bold text-wrap break-word"
            >
              Olá {{ getFirstName }},
            </v-card-title>
            <v-card-subtitle class="text-h5 text--bold title font-weight-bold">
              que tal conferir os nossos planos?
            </v-card-subtitle>
            <v-card-text class="py-4 body-1">
              Como você é novo por aqui, que tal explorar os nossos diferentes
              planos para escolher aquele que mais atende às suas necessidades e
              objetivos?
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primarySuite"
                class="white--text text-none text-body-1"
                width="100%"
                large
                outlined
                elevation="0"
                rounded
                @click="redirectToSelfCheckout"
              >
                Conhecer os Planos
                <v-icon v-text="'mdi-arrow-right'" class="next-icon-2" right />
              </v-btn>
            </v-card-actions>
            <a
              v-if="!$vuetify.breakpoint.mdAndUp"
              style="font-size: 10px"
              href="https://storyset.com/communication"
              >Communication illustrations by Storyset</a
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { mapGetters, mapMutations } from "vuex";
import {
  redirectToTheCommercialManagerGoogleCalendar,
  insertMinutes,
} from "@/helpers/services/utils";
import { links } from "@/helpers/variables/links";
import moment from "moment";
export default {
  name: "ScheduleMeeting",

  data: () => ({
    scheduleMeetingSnackbar: false,
    links,
    scheduleImage: require("@/assets/images/agendar_reuniao.svg"),
  }),

  computed: {
    ...mapGetters([
      "currentUser",
      "company",
      "showScheduleMeeting",
      "loading",
      "primaryColor",
      "secondaryColor",
      "mainDomain",
      "isSmallClient",
    ]),

    getFirstName() {
      const names = this.currentUser.name.split(" ");
      return names[0];
    },
  },

  methods: {
    ...mapMutations(["SET_MEETING_VISIBILITY"]),

    scheduleMeeting(schedule = true) {
      this.scheduleMeetingSnackbar = false;
      this.SET_MEETING_VISIBILITY(false);

      if (schedule) {
        const clicked_from = "Modal de agendamento (dashboard)";
        redirectToTheCommercialManagerGoogleCalendar(clicked_from);
        localStorage.setItem("scheduleMeeting", "true");
        this.logScheduleMeetingInAnalytics();
        return;
      }

      localStorage.setItem("scheduleMeeting", "false");
    },

    logScheduleMeetingInAnalytics() {
      const { main_domain } = this.company;
      const analytics = getAnalytics();

      logEvent(analytics, "schedule_meeting", {
        main_domain,
        clicked_where: "clicked from snackbar",
        date: moment().format("DD/MM/YYYY"),
      });
    },

    initScheduling() {
      if (!this.showScheduleMeeting) return;

      const currentDate = moment();
      if (this.hasPassed15Minutes()) {
        localStorage.setItem(
          "lastVisit",
          insertMinutes(15, currentDate).format()
        );
        this.scheduleMeetingSnackbar = true;
        this.SET_MEETING_VISIBILITY(true);
      }
    },

    redirectToSelfCheckout() {
      this.scheduleMeeting(false);

      const analytics = getAnalytics();

      logEvent(analytics, "go_to_self_checkout", {
        click: "schedule_dialog",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description:
          "Clicou no botão de 'Conhecer os planos' da modal de agendamento",
      });

      this.$router.push({ name: "BillingUpgradePlan" });
    },

    hasPassed15Minutes() {
      const now = moment();
      const lastVisit = localStorage.getItem("lastVisit");
      if (!lastVisit) return true;

      return now.isAfter(lastVisit);
    },
  },
  beforeMount() {
    document.addEventListener("mouseleave", this.initScheduling);
  },
  beforeDestroy() {
    document.removeEventListener("mouseleave", this.initScheduling);
  },
};
</script>

<style>
.v-btn:hover .next-icon-1,
.v-btn:hover .next-icon-2 {
  transform: translateX(5px); /* Ajuste o valor conforme necessário */
  transition: transform 0.3s ease-in-out;
}
</style>
