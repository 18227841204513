import {
  getTotalPlanPrice,
  getTotalPlanPriceWithoutDiscount,
  getPriceByPeriod,
  getTotalServicePrice,
} from "@/helpers/services/billing";
import { isAnnual } from "@/helpers/services/companyPlan";
import {
  formatDateForDatabase,
  today,
  greaterOrEqualToday,
  addDays,
} from "@/helpers/services/utils";

import { SUBSCRIPTIONS } from "@/helpers/variables/backendConstants";

import { defaultDiscounts } from "@/helpers/variables/translateString";

const state = () => ({
  shoppingCart: [],
  servicesCart: [],
  planInCart: {},
});

const getters = {
  planInCart: (state) => state.planInCart,

  hasPlanInCart: (state) => Object.keys(state.planInCart).length > 0,

  servicesCart: (state) => state.servicesCart,

  servicesCartKeys: (state) => state.servicesCart.map((item) => item.key),

  hasServicesInCart: (state) => state.servicesCart.length > 0,

  planCart: (state) => [state.planInCart],

  checkoutCart: (state, getters) => [
    ...getters.planCart,
    ...getters.servicesCart,
  ],

  shoppingCartSize: (state, getters) => getters.checkoutCart,

  cartTotal: (state, getters) =>
    getters.planCartTotal + getters.servicesCartTotal,

  planCartTotal: (state, getters) => {
    const { subscription_type, price } = getters.planInCart;
    const total = getTotalPlanPrice(
      getters.currentLicensesInCart,
      subscription_type,
      getters.planDiscountPercent,
      price
    );
    return total;
  },

  planDiscountPercent: (state, getters) => {
    if (getters.planInCart) {
      return getters.planInCart.discount > 1
        ? getters.planInCart.discount / 100
        : getters.planInCart.discount;
    }
    return null;
  },

  servicesCartTotal: (state, getters) => {
    return getters.servicesCart.reduce((acc, service) => {
      const { discount, price } = service;

      const updatedDiscount = discount > 1 ? discount / 100 : discount;

      if (updatedDiscount > 0) {
        acc += getTotalServicePrice(price, updatedDiscount);
      } else {
        acc += price;
      }

      return acc;
    }, 0);
  },

  planCartTotalByPeriod: (state, getters) => {
    const { subscription_type, price } = getters.planInCart;
    return getPriceByPeriod(
      subscription_type,
      price,
      getters.planDiscountPercent
    );
  },

  /* Discount in money (R$) of cart, includes plan and services */
  discountInCart: (state, getters) =>
    getters.planDiscount + getters.servicesDiscount,

  /* Discount in money (R$) of selected plan */
  planDiscount: (state, getters) => {
    const { subscription_type, price } = getters.planInCart;

    const valueWithoutDiscount = getTotalPlanPriceWithoutDiscount(
      getters.currentLicensesInCart,
      subscription_type,
      price
    );

    return valueWithoutDiscount - getters.planCartTotal;
  },

  /* Discount in money (R$) of services */
  servicesDiscount: (state, getters) => {
    const totalWithoutDiscount = getters.servicesCart.reduce((acc, service) => {
      acc += service.price;
      return acc;
    }, 0);

    return totalWithoutDiscount - getters.servicesCartTotal;
  },

  currentLicensesInCart: (state, getters, rootState, rootGetters) => {
    const isEdit = rootGetters.clientHasThisPlan;

    if (isEdit) {
      if (isAnnual(getters.planInCart.subscription_type)) {
        return getters.planInCart.max_licenses;
      }
      return getters.planInCart.licenses;
    } else {
      if (getters.planInCart.max_licenses > getters.planInCart.licenses) {
        return getters.planInCart.max_licenses;
      } else {
        return getters.planInCart.licenses;
      }
    }
  },

  hasDiscountInCart: (state, getters) => getters.discountInCart > 0,

  /* Get the most recent trial end date of the trial plan */
  nearestEndTrialDate: (state, getters, rootState, rootGetters) => {
    const nearestEndTrialIndex = 0;

    const trialDates = rootGetters.impactedCompanyPlansInTrial
      .map((companyPlan) => companyPlan.end_trial)
      .sort((a, b) => new Date(a) - new Date(b));

    if (trialDates.length) {
      return trialDates[nearestEndTrialIndex];
    }

    return null;
  },

  /* Get the most recent trial end date of the active plan */
  nearestEndTrialActivePlans: (state, getters, rootState, rootGetters) => {
    const nearestEndTrialIndex = 0;

    const trialDates = rootGetters.impactedActiveCompanyPlans
      .map((companyPlan) => companyPlan.end_trial)
      .sort((a, b) => new Date(a) - new Date(b));

    if (trialDates.length) {
      return trialDates[nearestEndTrialIndex];
    }

    return null;
  },

  orderNextBilling: (state, getters) => {
    if (getters.nearestEndTrialDate) {
      return greaterOrEqualToday(getters.nearestEndTrialDate)
        ? formatDateForDatabase(addDays(getters.nearestEndTrialDate, 1))
        : formatDateForDatabase(today);
    }

    return formatDateForDatabase(today);
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  setPlanCart(state, plan) {
    state.planInCart = plan;
  },

  setServiceCart(state, services) {
    state.servicesCart = services;
  },

  addServiceToCart(state, item) {
    state.servicesCart.push(item);
  },

  removeServiceFromCart(state, item_to_remove) {
    const index = state.servicesCart.findIndex(
      (item) => item.key === item_to_remove.key
    );
    state.servicesCart.splice(index, 1);
  },

  editServiceInstallment(state, { service_key, value }) {
    const index = state.servicesCart.findIndex(
      (item) => item.key === service_key
    );

    state.servicesCart[index].installment = value;
  },

  setServiceDiscount(state, { service_key, value }) {
    const index = state.servicesCart.findIndex(
      (item) => item.key === service_key
    );

    state.servicesCart[index].discount = value;
  },

  clearServiceCart(state) {
    state.servicesCart = [];
  },

  clearCart(state) {
    state.planInCart = {};
    state.servicesCart = [];
  },

  setLicenseNumber(state, licenses) {
    state.planInCart.licenses = licenses;
  },

  setMaxLicenseNumber(state, licenses) {
    state.planInCart.max_licenses = licenses;
  },

  setSubscriptionType(state, subscription_type) {
    state.planInCart.subscription_type = subscription_type;
  },

  setEndTrial(state, date) {
    state.planInCart.end_trial = date;
  },

  setDiscount(state) {
    if (state.planInCart.subscription_type === SUBSCRIPTIONS.ANNUAL) {
      state.planInCart.discount =
        defaultDiscounts[state.planInCart.subscription_type];
    } else {
      state.planInCart.discount = 0;
    }
  },

  resetPlanInCart(state) {
    state.planInCart = {};
  },

  resetTransactionDates(state) {
    state.planInCart.init_transaction = null;
    state.planInCart.end_transction = null;
  },

  updatePlanInCart(state, { key, value }) {
    if (Object.keys(state.planInCart).includes(key)) {
      state.planInCart[key] = value;
    }
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
