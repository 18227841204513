<template>
  <v-app>
    <v-container class="pa-0 ma-0">
      <v-overlay :value="true" opacity="1" z-index="200" color="primary">
        <v-card outlined color="primary darken-1">
          <v-row v-if="loadingVerification || step === 4" justify="center">
            <v-col align="center">
              <v-progress-circular
                width="3"
                size="70"
                color="white"
                indeterminate
                class="mt-5 mb-4"
              >
                <Logo product="conecta_suite" height="45" white class="blink" />
              </v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-else justify="center">
            <v-col align="center">
              <Logo
                product="conecta_suite"
                width="200"
                full
                white
                class="mb-3"
              />
            </v-col>
          </v-row>
          <v-row justify="center" v-show="step === 2">
            <v-col align="center" class="pt-0">
              <v-row justify="center">
                <v-col align="center" class="pt-0">
                  <div
                    class="text-h6 font-weight-regular mb-5"
                    style="white-space: nowrap"
                  >
                    {{
                      showRecaptcha ? "Resolva o 'Captcha'" : "Insira o código"
                    }}
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col align="center" class="pt-0">
                  <div class="pt-1" style="max-width: 423px; padding: 0px 40px">
                    Após resolvê-lo, você receberá um SMS com um código de 6
                    dígitos para inserir abaixo
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center" v-show="showRecaptcha">
                <v-col align="center" class="pt-0">
                  <div
                    id="recaptcha-container"
                    style="width: 304px; height: 78px"
                  ></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <v-form @submit.prevent="" ref="phoneForm">
                  <v-row justify="center">
                    <div
                      class="text-h6 font-weight-regular mb-6"
                      style="white-space: nowrap"
                    >
                      Confirme que você não é um robô
                    </div>
                  </v-row>
                  <v-row justify="center">
                    <div class="pt-2" style="white-space: nowrap">
                      Receba um código de verificação no seu smartphone
                    </div>
                  </v-row>
                  <v-row justify="center">
                    <v-col align="center" cols="12" sm="8" md="9">
                      <InternationalPhone
                        color="accent"
                        label="Telefone"
                        hint="Insira um número de telefone com o DDI, DDD e número"
                        :phone="phone"
                        :preferred-countries="['br']"
                        :rules="rulePhoneNumber"
                        outlined
                        @update="phone = $event"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col align="center" cols="12" sm="8" md="9">
                      <div class="font-weight-light">
                        Nós verificaremos esse número por SMS (sujeito a
                        cobranças).
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-form
                  v-show="!showRecaptcha"
                  @submit.prevent=""
                  ref="codeForm"
                  lazy-validation
                >
                  <v-otp-input
                    v-model="otp"
                    @finish="nextStep"
                    length="6"
                    type="number"
                    color="accent"
                    class="d-flex justify-center body-2"
                    style="width: 300px; margin: auto"
                  ></v-otp-input>
                  <v-input :rules="ruleOtp" :value="otp" />
                </v-form>
              </v-card-text>
              <v-card-subtitle
                class="d-flex justify-center"
                v-if="confirmationResult"
              >
                <span v-if="timer !== 0"
                  >O código expira em {{ timer }} segundos</span
                >
                <span v-else>Código expirado</span>
              </v-card-subtitle>
            </v-window-item>

            <v-window-item :value="3">
              <v-card-text>
                <v-row justify="center">
                  <div
                    class="text-h6 font-weight-regular mb-6"
                    style="white-space: nowrap"
                  >
                    Algo deu errado
                  </div>
                </v-row>
                <v-row justify="center">
                  <div class="pt-2 px-5" style="white-space: nowrap">
                    {{ errorMessage }}
                  </div>
                </v-row>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="4">
              <v-card-text>
                <v-row justify="center">
                  <div
                    class="text-h6 font-weight-regular mb-6"
                    style="white-space: nowrap"
                  >
                    Validação concluída
                  </div>
                </v-row>
                <v-row justify="center">
                  <div
                    class="pt-2 px-5 font-weight-light"
                    style="white-space: nowrap"
                  >
                    Você será redirecionado para a aplicação.
                  </div>
                </v-row>
              </v-card-text>
            </v-window-item>
          </v-window>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="!changeOption"
              color="accent"
              :disabled="
                step === 3 ||
                step === 4 ||
                (step === 2 && !confirmationResult) ||
                loadingVerification
              "
              @click="nextStep"
            >
              Próximo
            </v-btn>
            <v-btn
              v-else
              color="accent"
              :disabled="step === 3 || step === 4"
              @click="saveNumberAndPass"
              :loading="loading"
            >
              Continuar sem código
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  getAuth,
  useDeviceLanguage,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { rulePhoneNumber, removeMask } from "@/helpers/services/utils";

export default {
  name: "AccessForm",
  data: () => ({
    step: 1,
    codeIsValid: true,
    otp: null,
    phone: "",
    showRecaptcha: false,
    confirmationResult: null,
    errorMessage: "",
    rulePhoneNumber: rulePhoneNumber.concat([
      (v) => !!v || "Digite um número de telefone",
    ]),
    loadingVerification: null,
    timer: 60,
    changeOption: false,
    loading: false,
  }),
  computed: {
    ...mapGetters(["token"]),
    ruleOtp() {
      return [
        (v) => (!!v && v.length == 6) || "O código deve conter 6 dígitos.",
        () => !!this.codeIsValid || "Código inválido.",
      ];
    },
  },
  methods: {
    ...mapMutations(["setCompany"]),
    async withPhoneNumber(phoneNumber) {
      const auth = getAuth();
      useDeviceLanguage(auth);
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "normal",
            "expired-callback": (a) => {
              console.error(a);
            },
          },
          auth
        );
      }
      this.showRecaptcha = true;
      if (!this.confirmationResult) {
        try {
          const confirmationResult = await signInWithPhoneNumber(
            auth,
            phoneNumber,
            window.recaptchaVerifier
          );
          if (confirmationResult && confirmationResult.verificationId) {
            this.confirmationResult = confirmationResult;
            this.showRecaptcha = false;
            this.updateVerifiedPhone(this.phone, "CODE_SENT");
            this.initializedTimer();
          }
        } catch (error) {
          console.error(error);
          if (error.message.includes("auth/too-many-requests")) {
            this.step = 3;
            this.errorMessage =
              "Por favor, aguarde um pouco antes de realizar outra requisição.";
            this.updateVerifiedPhone(this.phone, "TOO_MANY_REQUESTS");
          }
        }
      } else {
        this.initializedTimer();
      }
    },
    async updateVerifiedPhone(phoneNumber, status_code) {
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/verify`;
      const auth = {
        headers: {
          Authorization: this.token,
        },
      };
      const verified_phone = "+" + removeMask("phone", phoneNumber);
      try {
        return await this.$axios.put(
          url,
          { verified_phone, status_code },
          auth
        );
      } catch (e) {
        this.step = 3;
        this.errorMessage =
          "Não foi possível enviar o código de verificação. Tente novamente.";
        throw e;
      }
    },
    async nextStep() {
      if (this.step == 1 && this.$refs.phoneForm.validate()) {
        this.step = 2;
        await this.withPhoneNumber(this.phone);
        localStorage.setItem("phone", String(this.phone));
      } else if (
        this.step == 2 &&
        (this.$refs.codeForm.validate() || (this.otp && this.otp.length === 6))
      ) {
        this.loadingVerification = true;
        try {
          const response = await this.confirmationResult.confirm(this.otp);
          if (response) {
            this.updateCompany(this.phone, "SUCCESS");
          }
        } catch (error) {
          console.error(error);
          if (error.message.includes("auth/invalid-verification-code")) {
            this.codeIsValid = false;
            this.updateVerifiedPhone(this.phone, "INVALID_CODE");
          } else if (error.message.includes("auth/code-expired")) {
            this.step = 3;
            this.errorMessage = "Código expirado.";
            this.updateVerifiedPhone(this.phone, "EXPIRED_CODE");
          } else {
            this.step = 3;
            this.errorMessage = "Erro desconhecido.";
            this.updateVerifiedPhone(this.phone, "UNKNOWN_ERROR");
          }
        }
        this.loadingVerification = false;
      }
    },
    initializedTimer() {
      let seconds = 60;
      return setInterval(() => {
        if (seconds > 0) {
          seconds--;
          this.timer = seconds;
        } else {
          clearInterval();
          this.changeOption = true;
        }
      }, 1000);
    },
    saveNumberAndPass() {
      const phone = this.phone || localStorage.getItem("phone");
      this.loading = true;
      this.updateCompany(phone, "REALESED_FOR_TIME_EXPIRED")
        .then(() => localStorage.removeItem("phone"))
        .finally(() => (this.loading = false));
    },
    async updateCompany(phone, code) {
      const { data } = await this.updateVerifiedPhone(phone, code);
      this.codeIsValid = true;
      this.step = 4;
      setTimeout(() => {
        if (data) {
          this.setCompany(data);
        }
      }, 5000);
    },
  },
  async beforeMount() {
    const auth = getAuth();
    if (auth.currentUser) {
      await signOut(auth);
    }
  },
};
</script>

<style>
.theme--dark.v-otp-input .v-input .v-input__control .v-input__slot {
  background: var(--v-primary) 80% !important;
}
</style>
