export const status = {
  ACTIVE: "Ativo",
  TRIAL: "Trial",
  TRIAL_EXPIRED: "Expirado",
  DISABLED: "Desativado",
  EXPIRED: "Expirado",
  SUSPENDED: "Suspenso",
  CANCELLED: "Cancelado",
  CANCELLATION_DATE: "Cancelado",
};

export const planPayment = {
  FREE: "gratuito",
  FLEX: "mensalmente",
  ANNUAL: "anualmente",
  QUARTELY: "a cada 3 meses",
  SEMESTER: "a cada 6 meses",
  BIENNIUM: "bienalmente",
};

export const planPeriodic = {
  TRIAL: "Trial",
  ANNUAL: "Anual",
  FLEX: "Mensal",
  QUARTELY: "Trimestral",
  SEMESTER: "Semestral",
  BIENNIUM: "Bienal (2 anos)",
  EXTRA: "Extras",
  ONCE: "Único",
  FREE: "Gratuito",
};

export const planPeriodicDescription = {
  TRIAL: "Período de teste",
  ANNUAL: "anual",
  FLEX: "mensal",
  QUARTELY: "trimestral",
  SEMESTER: "semestral",
  BIENNIUM: "bienal",
  EXTRA: "licenças extras",
  ONCE: "Serviço único",
  FREE: "gratuito",
};

export const translatePeriods = {
  FLEX: "mês",
  ANNUAL: "ano",
  SEMESTER: "meio ano",
  QUARTELY: "trimestre",
  BIENNIUM: "biênio",
  ONCE: "serviço",
  FREE: "gratuito",
};

export const monthsByPeriod = {
  ONCE: 0,
  FLEX: 1,
  ANNUAL: 12,
  SEMESTER: 6,
  QUARTELY: 3,
  BIENNIUM: 24,
  TRIAL: 0,
};

export const defaultDiscounts = {
  FLEX: 0,
  ANNUAL: 1 - (1 - 2 / monthsByPeriod.ANNUAL),
  SEMESTER: 0,
  QUARTELY: 0,
  BIENNIUM: 0,
  TRIAL: 0,
};

export const productNames = {
  conecta_suite: "Conecta Suite",
  conecta_control_mobile: "Advanced Logout",
  conecta_control: "Conecta Control",
  conecta_sign: "Conecta Sign",
  conecta_ad_sync: "Active Directory Integration",
  conecta_track: "Conecta Track",
  integracao_ponto_mais: "Ponto Mais Integration",
  service: "Serviço",
  sign_automation: "Sign Automation",
  conecta_control_integrations: "Integrações - Control",
};

export const translateResellerTypes = {
  DIRECT: "Direta",
  INTERMEDIARY: "Intermediária",
};

export const historyLogContext = {
  company_plan: "Plano da empresa",
  user_permission: "Permissão de usuário",
  user_delegates: "Delegação de acesso",
  edit_tags: "Edição de informações",
  edit_user_tags: "Edição de informações de usuário",
};

export const historyLogModuleName = {
  billing: "Faturamento",
  inventory: "Inventário digital",
  company_profile: "Perfil da Empresa",
  manage_admins: "Permissões de Usuário",
  reseller: "Conecta Partners",
  conecta_control: "Conecta Control",
  conecta_track: "Conecta Track",
  conecta_sign: "Conecta Sign",
  conecta_suite: "Conecta Suite",
  sign_automation: "Sign Automation",
};
