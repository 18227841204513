import { monthsByPeriod } from "@/helpers/variables/translateString";
import { STATUS } from "@/helpers/variables/backendConstants.js";

/**
 * Function to calculate the price of a plan to a certain number of users and subscription type.
 * @param {Number} licenses The number of licenses to calculate
 * @param {String} subscription_type The type os subscription selected
 * @param {Number} discount The percentage of discount to apply
 * @param {Number} plan_price The price of the plan per license
 * @return {Number} The plan price
 */
export function getDiscountValue(
  licenses,
  subscription_type,
  discount,
  plan_price
) {
  const priceWithDiscount = getTotalPlanPrice(
    licenses,
    subscription_type,
    discount,
    plan_price
  );
  const priceWithoutDiscount = getTotalPlanPriceWithoutDiscount(
    licenses,
    subscription_type,
    plan_price
  );
  return priceWithoutDiscount - priceWithDiscount;
}

/**
 * Calculates the discount percentage based on the transaction details.
 * @param {Object} transaction The transaction object containing the details.
 * @param {Object} plans All the plans objects containing the details.
 * @param {Object} services All the occasional services objects containing the details.
 * @return {Number} The discount percentage.
 */
export function getDiscountPercentageValue(transaction, plans, services) {
  const planUsed = plans.find((plan) => plan.name === transaction.title);
  const serviceUsed = services.find(
    (service) => service.name === transaction.title
  );
  if (serviceUsed) {
    const discount =
      ((serviceUsed.price - transaction.total_price) / serviceUsed.price) * 100;
    if (discount === 0 || isNaN(discount)) {
      return "--";
    }
    return `${discount.toFixed(2)}%`;
  } else if (planUsed) {
    const discount =
      ((planUsed.price - transaction.price_by_licenses) / planUsed.price) * 100;
    if (discount === 0 || isNaN(discount)) {
      return "--";
    }
    return `${discount.toFixed(2)}%`;
  } else {
    return "--";
  }
}

/**
 * Function to calculate the price of a service.
 * @param {Number} installments The number of installments to calculate
 * @param {Number} discount The percentage of discount to apply
 * @param {Number} plan_price The price of the plan per license
 * @return {Number} The plan price
 */
export function getTotalServicePriceByInstallment(
  installments,
  discount,
  plan_price
) {
  const totalPrice = getPriceByPeriod("ONCE", plan_price, discount);
  return totalPrice / installments;
}

/**
 * Function to calculate the price of a service.
 * @param {Number} discount The percentage of discount to apply
 * @param {Number} service_price The price of the service
 * @return {Number} The plan price
 */
export function getTotalServicePrice(service_price, discount = 0) {
  if (discount && discount > 0) {
    const totalDiscount = 1 - discount;
    return service_price * totalDiscount;
  }
  return service_price;
}

/**
 * Function to calculate the discount in R$ of a service.
 * @param {Number} discount The percentage of discount to apply
 * @param {Number} service_price The price of the service per license
 * @return {Number} The plan price
 */
export function getServiceDiscountValue(service_price, discount = 0) {
  const totalWithDiscount = getTotalServicePrice(service_price, discount);

  if (discount > 0) {
    const totalWithoutDiscount = getTotalServicePrice(service_price, 0);
    return totalWithoutDiscount - totalWithDiscount;
  }
  return totalWithDiscount;
}

/**
 * Function to calculate the price of a plan to a certain number of users and subscription type.
 * @param {Number} licenses The number of licenses to calculate
 * @param {String} subscription_type The type os subscription selected
 * @param {Number} discount The percentage of discount to apply
 * @param {Number} plan_price The price of the plan per license
 * @return {Number} The plan price
 */
export function getTotalPlanPrice(
  licenses,
  subscription_type,
  discount,
  plan_price
) {
  const totalPrice = getPriceByPeriod(subscription_type, plan_price, discount);
  return totalPrice * licenses;
}

/**
 * Function to calculate the price of a plan to a certain number of users and subscription type.
 * @param {Number} licenses The number of licenses to calculate
 * @param {String} subscription_type The type os subscription selected
 * @param {Number} plan_price The price of the plan per license
 * @return {Number} The plan price
 */
export function getTotalPlanPriceWithoutDiscount(
  licenses,
  subscription_type,
  plan_price
) {
  const totalPrice = getPriceByPeriod(subscription_type, plan_price);
  return totalPrice * licenses;
}

/**
 * Function to calculate the price of a license per period (subscription type).
 * @param {String} subscription_type The selected subscription type
 * @param {Number} plan_price The price of the plan per license
 * @param {Number} discount The percentage of discount to apply
 */
export function getPriceByPeriod(
  subscription_type,
  plan_price,
  discount = false
) {
  if (discount) {
    const totalDiscount = 1 - discount;
    return monthsByPeriod[subscription_type] * plan_price * totalDiscount;
  }
  return monthsByPeriod[subscription_type] * plan_price;
}

/**
 * Function to calculate the price of a license.
 * @param {Number} plan_price The price of the plan per license
 * @param {Number} discount The percentage of discount to apply
 */
export function getLicensePrice(plan_price, discount) {
  const totalDiscount = 1 - discount;
  return plan_price * totalDiscount;
}

/**
 * Function to define if the plan is active
 * @param {String} status The current plan status
 * @return {Boolean}
 */
export function isActivePlan(status) {
  return status === STATUS.ACTIVE;
}

/**
 * Function to define if the plan is disabled
 * @param {String} status The current plan status
 * @return {Boolean}
 */
export function isDisabledPlan(status) {
  return status === STATUS.DISABLED;
}

/**
 * Function to define if the plan is in trial
 * @param {String} status The current plan status
 * @return {Boolean}
 */
export function isTrialPlan(status) {
  return status === STATUS.TRIAL;
}
